export const dateParsed = (date) => {
    const newDate = dateToLocaleString(date);
    return newDate.toString() === "Invalid Date" ? "" : newDate;
};

export const addDaysToDate = (date, days) => {
    const customDate = new Date(date);
    customDate.setDate(customDate.getDate() + days);
    return customDate;
};

const dateToLocaleString = (date) => {
    const utcDate = getUTCDate(date);
    const fecha = utcDate.toLocaleString("es-ES", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
    });
    return fecha;
};

const getUTCDate = (date) => {
    const d = new Date(date);
    const utcDate = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate(),
    );
    return utcDate;
};
