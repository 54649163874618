import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    Thead,
    Stack,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    IconButton,
    ButtonGroup,
    Center,
    Text,
    Flex,
    Checkbox,
    Spacer,
    Skeleton,
} from "@chakra-ui/react";
import {
    ChevronDownIcon,
    DeleteIcon,
    EditIcon,
    InfoIcon,
} from "@chakra-ui/icons";
import Popover from "../components/Popover";
import Drawer from "../components/Drawer";
import getDescendantProp from "../util/getDescendantProp";
import { useLocation } from "react-router-dom";
import remitosRequests from "../api/remitosRequests";
import { useForm } from "react-hook-form";
import { CustomAlert } from "../components/CustomAlert";
import { formatCurrency } from "./currencyHelper";
import { dateParsed } from "./dateHelper";

const generateTableContent = (
    columns,
    rows,
    renderData,
    request,
    drawerForm,
) => {
    const location = useLocation();
    const { pathname } = location;
    const isSpare = pathname.includes("spare");
    const isOil = pathname.includes("oil");

    const [selectedAllRemitos, setselectedAllRemitos] = useState(false);

    const defaultValues = {
        remitosToBeProcessed: [],
        collapsibleRow: [],
    };

    const { register, handleSubmit, watch, setValue, reset } = useForm({
        defaultValues,
    });
    const remitosToBeProcessed = watch("remitosToBeProcessed");
    const collapsibleRow = watch("collapsibleRow");

    const onSubmit = async () => {
        if (remitosToBeProcessed) {
            await remitosRequests.markAsProcessed(remitosToBeProcessed);
            reset(defaultValues);
            renderData.setRender(!renderData.render);
        }
    };

    const handleAllRemitos = () => {
        setselectedAllRemitos(!selectedAllRemitos);
        return rows.map((row, index) => {
            const IsStatusPending = row.status === "pending";
            if (IsStatusPending && !selectedAllRemitos) {
                return setValue(`remitosToBeProcessed.${index}`, row.id);
            }
            return setValue(`remitosToBeProcessed.${index}`, false);
        });
    };

    const thead = columns.map((column, index) => (
        <Th key={uuidv4()}>
            <Stack>
                <Text>
                    {columns.find((col) => col.position === index).displayName}
                </Text>
            </Stack>
            {column.key === "forProcessing" && (
                <Stack>
                    <Checkbox
                        onChange={handleAllRemitos}
                        isChecked={selectedAllRemitos}
                    >
                        Seleccionar Todos
                    </Checkbox>
                    <Button
                        type="submit"
                        fontSize="xs"
                        colorScheme="blue"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Procesar remitos
                    </Button>
                </Stack>
            )}
            {column.key === "collapse" && (
                <Stack>
                    <ChevronDownIcon />
                </Stack>
            )}
        </Th>
    ));

    let tbody = (
        <Tr>
            <Td colSpan={columns.length}>
                <Skeleton minH={16} my={1} />
                <Skeleton minH={16} my={1} />
                <Skeleton minH={16} my={1} />
                <Skeleton minH={16} my={1} />
                <Skeleton minH={16} my={1} />
            </Td>
        </Tr>
    );

    const isValidBody = Array.isArray(rows) && rows.length > 0;
    if (isValidBody) {
        tbody = rows.map((row, index) => {
            return (
                <>
                    <Tr key={uuidv4()}>
                        {columns.map((column) => {
                            const rowValue = getDescendantProp(row, column.key);
                            if (column.key === "collapse") {
                                const rowCollapse = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Stack>
                                            <Checkbox
                                                id={row.id}
                                                value={row.id}
                                                {...register(
                                                    `collapsibleRow.${index}`,
                                                )}
                                            >
                                                Expandir
                                            </Checkbox>
                                        </Stack>
                                    </Td>
                                );
                                return rowCollapse;
                            }
                            if (
                                column.key === "price" ||
                                column.key === "net" ||
                                column.key === "netPlusIva" ||
                                column.key === "total" ||
                                column.key === "amount" ||
                                column.key === "debe" ||
                                column.key === "haber" ||
                                column.key === "checkingAccount"
                            ) {
                                return (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        {formatCurrency(rowValue)}
                                    </Td>
                                );
                            }
                            if (column.key === "action") {
                                const isSpareOrOil = isSpare || isOil;
                                const handleType = isSpare
                                    ? "Repuesto"
                                    : "Aceite";
                                return (
                                    <Td
                                        // data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <ButtonGroup>
                                            {drawerForm && (
                                                <Drawer
                                                    activationMessage={`Editar ${
                                                        isSpareOrOil
                                                            ? handleType
                                                            : pathname
                                                    }`}
                                                    triggerButton={
                                                        <IconButton
                                                            colorScheme="blue"
                                                            aria-label="Edit client"
                                                            icon={<EditIcon />}
                                                        />
                                                    }
                                                >
                                                    {React.cloneElement(
                                                        drawerForm,
                                                        {
                                                            data: row,
                                                        },
                                                    )}
                                                </Drawer>
                                            )}
                                            {row.movements && (
                                                <Popover
                                                    message={
                                                        row.movements.length ===
                                                        0
                                                            ? "No hay movimentos"
                                                            : "Mostrando movimientos"
                                                    }
                                                    trigger={
                                                        <IconButton
                                                            colorScheme="yellow"
                                                            aria-label="Remove client"
                                                            icon={<InfoIcon />}
                                                        />
                                                    }
                                                    body={
                                                        <>
                                                            {row.movements
                                                                .length !== 0 &&
                                                                row.movements.map(
                                                                    (
                                                                        move,
                                                                        index,
                                                                    ) => (
                                                                        <Flex
                                                                            key={uuidv4()}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    alignItems:
                                                                                        "center",
                                                                                    display:
                                                                                        "flex",
                                                                                    padding:
                                                                                        "20px",
                                                                                    fontSize:
                                                                                        "21px",
                                                                                }}
                                                                            >
                                                                                {index +
                                                                                    1}
                                                                            </p>
                                                                            <Flex>
                                                                                <p>
                                                                                    Observación:{" "}
                                                                                    {
                                                                                        move.comment
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    {`Fecha/hora: ${new Date(
                                                                                        move.date,
                                                                                    ).toLocaleString(
                                                                                        "es-AR",
                                                                                    )}`}
                                                                                </p>
                                                                                {isOil && (
                                                                                    <p>
                                                                                        Litros
                                                                                        tomados:{" "}
                                                                                        {
                                                                                            move.littersTaken
                                                                                        }
                                                                                    </p>
                                                                                )}
                                                                                {isSpare && (
                                                                                    <p>
                                                                                        Cantidad:{" "}
                                                                                        {
                                                                                            move?.quantityTaken
                                                                                        }
                                                                                    </p>
                                                                                )}
                                                                                <hr />
                                                                            </Flex>
                                                                        </Flex>
                                                                    ),
                                                                )}
                                                        </>
                                                    }
                                                />
                                            )}
                                            {row.assignedProducts && (
                                                <Popover
                                                    message={
                                                        row.assignedProducts
                                                            .length === 0
                                                            ? "No hay productos asignados"
                                                            : "Productos asignados"
                                                    }
                                                    trigger={
                                                        <IconButton
                                                            colorScheme="yellow"
                                                            aria-label="Remove client"
                                                            icon={<InfoIcon />}
                                                        />
                                                    }
                                                    body={
                                                        <>
                                                            {row
                                                                .assignedProducts
                                                                .length !== 0 &&
                                                                row.assignedProducts.map(
                                                                    (
                                                                        product,
                                                                        index,
                                                                    ) => (
                                                                        <Flex
                                                                            key={uuidv4()}
                                                                        >
                                                                            <Center>
                                                                                <Text fontSize="4xl">
                                                                                    {index +
                                                                                        1}
                                                                                </Text>
                                                                            </Center>
                                                                            <Spacer />
                                                                            <Center>
                                                                                <Text fontSize="md">
                                                                                    {
                                                                                        product?.name
                                                                                    }
                                                                                </Text>
                                                                            </Center>
                                                                        </Flex>
                                                                    ),
                                                                )}
                                                        </>
                                                    }
                                                />
                                            )}
                                            <Popover
                                                message="¿Eliminar definitivamente?"
                                                trigger={
                                                    <IconButton
                                                        colorScheme="red"
                                                        aria-label="Remove client"
                                                        icon={<DeleteIcon />}
                                                    />
                                                }
                                                footer={
                                                    <Button
                                                        colorScheme="red"
                                                        onClick={async () => {
                                                            await request.deleteRecord(
                                                                row.id ||
                                                                    row._id,
                                                                row.type,
                                                            );
                                                            renderData.setRender(
                                                                !renderData.render,
                                                            );
                                                        }}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                }
                                            />
                                        </ButtonGroup>
                                    </Td>
                                );
                            }
                            if (column.key === "forProcessing") {
                                const rowForProcessing = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Stack>
                                            {row.status === "processed" ? (
                                                <Text>
                                                    Procesado / {row.statusId}
                                                </Text>
                                            ) : (
                                                <>
                                                    <Checkbox
                                                        id={row.id}
                                                        value={row.id}
                                                        {...register(
                                                            `remitosToBeProcessed.${index}`,
                                                        )}
                                                    >
                                                        Seleccionar
                                                    </Checkbox>
                                                </>
                                            )}
                                        </Stack>
                                    </Td>
                                );
                                return rowForProcessing;
                            }
                            if (column.key === "liters") {
                                return (
                                    <Td>{`${row.availableLitters} de ${row.liters}`}</Td>
                                );
                            }
                            if (column.key === "costPerLitter") {
                                return <Td>{`${row.costPerLitter} $`}</Td>;
                            }
                            if (column.key === "extras") {
                                const rowExtras = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <ol>
                                            {rowValue?.map((value) => (
                                                <li
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >{`${value.concepto}:${value.amount}`}</li>
                                            ))}
                                        </ol>
                                    </Td>
                                );
                                return rowExtras;
                            }
                            if (
                                column.key === "clientId" ||
                                column.key === "client" ||
                                column.key === "from" ||
                                column.key === "provider"
                            ) {
                                return (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>{rowValue?.name}</Text>
                                    </Td>
                                );
                            }
                            if (column.key === "checkId") {
                                const rowClient = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>{rowValue?.checkNumber}</Text>
                                        <Text>
                                            {rowValue?.status === "received" &&
                                                "Recibido"}
                                        </Text>
                                    </Td>
                                );
                                return rowClient;
                            }
                            if (
                                column.key === "type" ||
                                column.key === "payment_type"
                            ) {
                                const rowClient = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>
                                            {rowValue === "recibo" && "Recibo"}
                                            {rowValue === "cash" && "Efectivo"}
                                            {rowValue === "others" && "Otros"}
                                            {rowValue === "check" && "Cheque"}
                                            {rowValue === "checkOwn" &&
                                                "Cheque propio"}
                                            {rowValue === "ton" && "Toneladas"}
                                            {rowValue === "batea" && "Batea"}
                                            {rowValue === "chasis" && "Chasis"}
                                            {rowValue === "orden de pago" &&
                                                "Orden de pago"}
                                            {rowValue === "orden de compra" &&
                                                "Orden de compra"}
                                            {rowValue === "factura" &&
                                                "Factura"}
                                        </Text>
                                    </Td>
                                );
                                return rowClient;
                            }
                            if (column.key === "productId") {
                                const rowProduct = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>
                                            {rowValue.lenght > 0 &&
                                                rowValue?.map(
                                                    (val) => val?.name,
                                                )}
                                        </Text>
                                    </Td>
                                );
                                return rowProduct;
                            }
                            if (column.key === "product") {
                                const rowProduct = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        {rowValue && (
                                            <Text>
                                                {rowValue?.name || rowValue}
                                            </Text>
                                        )}
                                    </Td>
                                );
                                return rowProduct;
                            }
                            if (
                                column.key === "date" ||
                                column.key === "expirationDate"
                            ) {
                                const rowProvider = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>{dateParsed(rowValue)}</Text>
                                    </Td>
                                );
                                return rowProvider;
                            }
                            if (column.key === "check") {
                                const rowCheck = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>{rowValue?.checkNumber}</Text>
                                    </Td>
                                );
                                return rowCheck;
                            }
                            if (column.key === "status") {
                                const rowStatus = (
                                    <Td
                                        data-label={column.displayName}
                                        key={uuidv4()}
                                    >
                                        <Text>
                                            {rowValue === "delivered" &&
                                                "Entregado"}
                                            {rowValue === "received" &&
                                                "En cartera"}
                                            {rowValue === "processed" &&
                                                "Procesado"}
                                            {rowValue === "pending" &&
                                                "Pendiente"}
                                        </Text>
                                    </Td>
                                );
                                return rowStatus;
                            }
                            return (
                                <Td
                                    data-label={column.displayName}
                                    key={uuidv4()}
                                >
                                    {rowValue}
                                </Td>
                            );
                        })}
                    </Tr>
                    {collapsibleRow[index] && (
                        <Tr>
                            <Td colSpan={columns.length}>
                                {console.log(row.charges)}
                                {row?.charges?.map((charge, i) => {
                                    return (
                                        <div>
                                            <div>
                                                {i + 1} / Tipo: {charge.type} /
                                                Total:{" "}
                                                {formatCurrency(charge.amount)}{" "}
                                                / Fecha:{" "}
                                                {dateParsed(charge.date)}
                                            </div>
                                        </div>
                                    );
                                })}
                                {row?.payments?.map((charge, i) => {
                                    return (
                                        <div>
                                            <div>
                                                {i + 1} / Tipo: {charge.type} /
                                                Total:{" "}
                                                {formatCurrency(charge.amount)}{" "}
                                                / Fecha:{" "}
                                                {dateParsed(charge.date)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Td>
                        </Tr>
                    )}
                </>
            );
        });
    }
    if (rows?.includes("Any")) {
        tbody = (
            <Tr>
                <Td colSpan={columns.length}>
                    <Center>
                        <CustomAlert
                            text={`No existen registros de ${pathname}`}
                            message={`Primero debes cargar uno`}
                        />
                    </Center>
                </Td>
            </Tr>
        );
    }

    if (rows === "vacio-por-fecha") {
        tbody = (
            <Tr>
                <Td colSpan={columns.length}>
                    <Center>
                        <CustomAlert
                            status="info"
                            text={`Atención! `}
                            message={`No encontramos registros en la fecha seleccionada`}
                        />
                    </Center>
                </Td>
            </Tr>
        );
    }

    if (rows === []) {
        tbody = (
            <Tr>
                <Td colSpan={columns.length}>
                    <Center>
                        <CustomAlert
                            text={`Cargando`}
                            message={`Se paciente`}
                        />
                    </Center>
                </Td>
            </Tr>
        );
    }
    return {
        head: (
            <Thead>
                <Tr>{thead}</Tr>
            </Thead>
        ),
        body: <Tbody>{tbody}</Tbody>,
    };
};

export default generateTableContent;
