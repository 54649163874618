import React from "react";
import { Table as ChakraTable, Box, Stack, Skeleton } from "@chakra-ui/react";
import "./styled.css";
const Table = ({ tableContent, loading }) => {
    return (
        <Box overflowX="overlay">
            <ChakraTable variant="striped">
                {loading ? (
                    <Stack>
                        <Skeleton height="5rem" />
                        <Skeleton height="5rem" />
                        <Skeleton height="5rem" />
                    </Stack>
                ) : (
                    <>
                        {tableContent.head}
                        {tableContent.body}
                    </>
                )}
            </ChakraTable>
        </Box>
    );
};

export default Table;
