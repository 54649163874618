import React, { useState, useEffect } from "react";
import { Stack, Text, Flex } from "@chakra-ui/layout";
import { useForm } from "react-hook-form";
import providerRequests from "../api/providerRequests";
import purchasesRequest from "../api/purchasesRequests";
import paymentRequests from "../api/paymentRequests";
import SelectProvider from "../components/Provider/SelectProvider";
import Table from "../components/Table";
import generateTableContent from "../util/generateTableContent";
import { formatCurrency } from "../util/currencyHelper";
import FiltersTable from "../components/Table/Filters";

const CCProvider = () => {
    const form = useForm({
        defaultValues: {
            providerId: undefined,
        },
    });
    const { watch } = form;
    const [render, setRender] = useState(false);
    const renderData = { render, setRender };
    const [providerData, setPurchasesProvider] = useState(false);
    const [providers, setProviders] = useState([]);
    const providerId = watch("providerId");
    const [rows, setRows] = useState([]);
    const [rowsFiltered, setRowsFiltered] = useState([]);

    handleProviders();
    handleProviderSelected();

    const columns = [
        { displayName: "Fecha", key: "date", position: 0 },
        { displayName: "Comprobante", key: "payment_type", position: 1 },
        { displayName: "N° Comprobante", key: "ncomprobante", position: 2 },
        { displayName: "Debe", key: "debe", position: 3 },
        { displayName: "Haber", key: "haber", position: 4 },
        { displayName: "Saldo", key: "total", position: 5 },
    ];

    const getDataPurchasesByIds = async () => {
        if (providerData?.purchases) {
            const purchasesById = await Promise.all(
                providerData?.purchases?.map(async (purchaseId) => {
                    const purchaseRecord = await purchasesRequest.getRecord(
                        purchaseId,
                    );
                    const parsedPurchase = purchaseRecord[0];
                    return { ...parsedPurchase };
                }),
            );
            const filterByPending = purchasesById?.filter(
                (purchase) => purchase.status === "pending",
            );
            const parsedDebe = filterByPending?.map((purchase) => {
                return {
                    ...purchase,
                    debe: purchase.total,
                    ncomprobante: purchase.invoiceId,
                    payment_type: "factura",
                };
            });
            return parsedDebe;
        }
        return [];
    };

    const getDataPayments = async () => {
        if (providerData?.payments) {
            const paymentsById = await Promise.all(
                providerData?.payments?.map(async (payment) => {
                    const purchaseRecord = await paymentRequests.getRecord(
                        payment,
                    );
                    const parsedPayment = purchaseRecord[0];
                    return { ...parsedPayment };
                }),
            );
            const parsedHaber = paymentsById?.map((payment) => {
                return {
                    ...payment,
                    haber: payment.total,
                    payment_type: "orden de pago",
                    ncomprobante: payment.paymentPdfId,
                };
            });
            return parsedHaber;
        }
        return [];
    };

    const generatePurchasesAndPayments = async () => {
        let total = 0;
        if (providerData) {
            const dataPurchases = await getDataPurchasesByIds();

            const dataPayments = await getDataPayments();

            if (dataPurchases && dataPayments) {
                const initialChecckingAccount =
                    generateInitialCheckingAccount();

                let paymentsAndPurchases = [...dataPurchases, ...dataPayments];
                paymentsAndPurchases.unshift(initialChecckingAccount);

                // sorting with latest date
                const filterdPayments = paymentsAndPurchases
                    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                    .map((paymentOrPruchase) => {
                        if (paymentOrPruchase.debe) {
                            total += paymentOrPruchase.debe;
                        }
                        if (paymentOrPruchase.haber) {
                            total -= Math.abs(paymentOrPruchase.haber);
                        }
                        return {
                            ...paymentOrPruchase,
                            total,
                        };
                    });
                return filterdPayments;
            }
        }
        return [];

        function generateInitialCheckingAccount() {
            let providerInitialCheckingAccount = {
                date: "",
                debe: null,
                haber: null,
                ncomprobante: "Valor en Cuenta Corriente Inicial",
            };
            if (providerData?.initial_checking_account > 0) {
                providerInitialCheckingAccount.debe =
                    providerData?.initial_checking_account;
            } else {
                providerInitialCheckingAccount.haber =
                    providerData?.initial_checking_account;
            }
            return providerInitialCheckingAccount;
        }
    };

    useEffect(async () => {
        const response = await generatePurchasesAndPayments();
        if (response.length === 0) {
            setRowsFiltered([]);
            return setRows("Any");
        }
        setRowsFiltered(response);
        return setRows(response);
    }, [providerData]);

    const purchasesTableContent = generateTableContent(
        columns,
        rows,
        renderData,
        providerRequests,
        null,
    );

    const rowsFilteredForPrint = {
        providerId: providerData?.id,
        checkingAccount: rows,
    };

    const TableComprasYPagosProveedores = (
        <>
            <FiltersTable
                title={"Compras y Pagos"}
                filteredData={rowsFiltered}
                originalData={rows}
                setData={setRows}
                request={providerRequests}
                customPrint
                filteredDataForPrint={rowsFilteredForPrint}
                customFilenamePdf={`Informe Saldo Cta Cte  ${providerData?.name}`}
            />
            <Table tableContent={purchasesTableContent} />
        </>
    );
    const Header = (
        <Flex
            direction={["column", "row"]}
            justifyContent="space-between"
            align="center"
            p={8}
        >
            <Stack p={3}>
                <Text fontSize="large">
                    Cuenta corriente proveedor:{" "}
                    <SelectProvider form={form} title=" " />
                </Text>
            </Stack>
            {providerId && (
                <>
                    <Stack p={3}>
                        <Text fontSize="xl">Saldo</Text>
                        <Text>
                            {formatCurrency(providerData?.checkingAccount)}
                        </Text>
                    </Stack>
                    <Stack p={3}>
                        <Text fontSize="xl">Datos Personales</Text>
                        <Flex direction="column" justifyContent="space-evenly">
                            <Text>
                                CUIT:
                                {providerData?.cuit}
                            </Text>
                            <Text>Dirección: {providerData?.address}</Text>
                            <Text>Comentario: {providerData?.comment}</Text>
                        </Flex>
                    </Stack>
                </>
            )}
        </Flex>
    );

    return (
        <Stack
            border="1px solid var(--chakra-colors-gray-200)"
            display="column"
            direction={["column", "row"]}
            p={4}
        >
            {Header}
            {TableComprasYPagosProveedores}
        </Stack>
    );

    function handleProviders() {
        useEffect(async () => {
            const providers = await providerRequests.getRecords();
            setProviders(providers);
        }, []);
    }

    function handleProviderSelected() {
        useEffect(() => {
            if (Array.isArray(providers)) {
                const providerFiltered = providers.find(
                    (provider) => provider.id === providerId,
                );

                setPurchasesProvider(providerFiltered);
            }
        }, [providerId, providers]);
    }
};

export default CCProvider;
