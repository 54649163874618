import React, { useState, useEffect } from "react";
import Table from "../../../components/Table";
import Drawer from "../../../components/Drawer";
import generateTableContent from "../../../util/generateTableContent";
import remitosRequest from "../../../api/remitosRequests";
import RemitosForm from "../../../components/Sales/RemitosForm";
import FiltersTable from "../../../components/Table/Filters";

const Remitos = () => {
    const [remitos, setRemitos] = useState([]);
    const [remitosFiltered, setRemitosFiltered] = useState([]);
    const [render, setRender] = useState(false);
    const renderData = { render, setRender };
    const drawerForm = <RemitosForm renderData={renderData} />;

    handleRemitos();

    const columns = [
        { displayName: "Cliente", key: "client", position: 0 },
        { displayName: "N° Remito", key: "remitoId", position: 1 },
        { displayName: "Concepto", key: "observation", position: 2 },
        { displayName: "Precio", key: "price", position: 3 },
        { displayName: "Estado", key: "status", position: 4 },
        { displayName: "Producto", key: "product", position: 5 },
        { displayName: "Tipo", key: "type", position: 6 },
        { displayName: "Toneladas", key: "tons", position: 7 },
        { displayName: "Procesar", key: "forProcessing", position: 8 },
        { displayName: "Fecha", key: "date", position: 9 },
        { displayName: "Acción", key: "action", position: 10 },
    ];

    const tableContent = generateTableContent(
        columns,
        remitos,
        renderData,
        remitosRequest,
        drawerForm,
    );

    return (
        <>
            <FiltersTable
                title={"Remitos"}
                filteredData={remitos}
                originalData={remitosFiltered}
                setData={setRemitos}
                request={remitosRequest}
                filterByClient
                filterByStatus
                customFilenamePdf="Liquidación"
            >
                <Drawer activationMessage="Cargar" size="xl">
                    <RemitosForm renderData={renderData} />
                </Drawer>
            </FiltersTable>
            <Table tableContent={tableContent} />
        </>
    );

    function handleRemitos() {
        useEffect(() => {
            (async () => {
                const remitos = await remitosRequest.getRecords();
                if (Array.isArray(remitos)) {
                    const sortyByDateRemitos = remitos?.sort(
                        (a, b) => Date.parse(a.date) - Date.parse(b.date),
                    );
                    setRemitos(sortyByDateRemitos);
                    setRemitosFiltered(sortyByDateRemitos);
                } else {
                    setRemitos(["Any"]);
                    setRemitosFiltered(["Any"]);
                }
            })();
        }, [render]);
    }
};

export default Remitos;
