import React, { useState, useEffect } from "react";
import generateTableContent from "../../util/generateTableContent";
import request from "../../api/chargesRequests";
import Drawer from "../../components/Drawer";
import Table from "../../components/Table";
import ChargesForm from "../../components/Charges/ChargesForm";

const Charges = () => {
    const [charges, setCharges] = useState([]);
    const [render, setRender] = useState(false);
    const renderData = { render, setRender };
    const drawerForm = <ChargesForm renderData={renderData} />;

    handleCharges();

    const columns = [
        { displayName: "", key: "collapse", position: 0 },
        { displayName: "Fecha", key: "date", position: 1 },
        { displayName: "N° Recibo", key: "chargePdfId", position: 2 },
        { displayName: "Cliente", key: "client", position: 3 },
        { displayName: "Imp. total", key: "total", position: 4 },
        { displayName: "Acción", key: "action", position: 5 },
    ];

    const tableContent = generateTableContent(
        columns,
        charges,
        renderData,
        request,
        drawerForm,
    );

    return (
        <>
            <Drawer activationMessage="Cargar Cobros" size="xxl">
                {drawerForm}
            </Drawer>
            <Table tableContent={tableContent} />
        </>
    );

    function handleCharges() {
        useEffect(() => {
            (async () => {
                const charges = await request.getRecords();
                setCharges(charges);
            })();
        }, [render]);
    }
};

export default Charges;
