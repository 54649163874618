import React, { useEffect, useRef } from "react";
import {
    Drawer as ChakraDrawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Flex,
    Stack,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import Link from "./Link";

const Drawer = ({
    children,
    activationMessage,
    triggerButton,
    defaultOpen,
    size,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef();

    useEffect(() => {
        defaultOpen && onOpen();
    }, [defaultOpen]);

    const defaultTrigger = (
        <Flex justifyContent="left">
            <Stack>
                <Link
                    ref={btnRef}
                    rightIcon={<AddIcon />}
                    colorScheme="teal"
                    variant="outline"
                    onClick={onOpen}
                    icon="add"
                    label={activationMessage}
                ></Link>
            </Stack>
        </Flex>
    );

    const actionButton = triggerButton
        ? React.cloneElement(triggerButton, { onClick: onOpen, ref: btnRef })
        : defaultTrigger;

    const childrenWithOnClose = React.cloneElement(children, { onClose });

    return (
        <>
            {actionButton}
            <ChakraDrawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
                size={size || "md"}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{activationMessage}</DrawerHeader>

                    <DrawerBody>{childrenWithOnClose}</DrawerBody>
                </DrawerContent>
            </ChakraDrawer>
        </>
    );
};

export default Drawer;
