import React, { useState, useEffect } from "react";
import { Select } from "@chakra-ui/select";
import clientRequests from "../../api/clientRequests";
import { Spinner } from "@chakra-ui/react";
import { Stack, Text } from "@chakra-ui/layout";

const SelectClient = (props) => {
    const { form, placeholder, title = "Listado de Clientes" } = props;

    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);

    const { register } = form;

    fetchClients();

    return (
        <Stack>
            <Text fontSize="xl">{title}</Text>
            {loading ? (
                <Spinner />
            ) : (
                <Select
                    variant="flushed"
                    placeholder={
                        placeholder ||
                        `${
                            Array.isArray(clients)
                                ? "Seleccione un cliente"
                                : "No existen clientes"
                        }`
                    }
                    {...register("clientId")}
                >
                    {Array.isArray(clients) &&
                        clients?.map((client) => (
                            <option value={client.id}>{client.name}</option>
                        ))}
                </Select>
            )}
        </Stack>
    );

    function fetchClients() {
        useEffect(async () => {
            setLoading(true);
            const clients = await clientRequests.getRecords();
            if (clients) {
                setClients(clients);
                return setLoading(false);
            }
        }, []);
    }
};

export default SelectClient;
