import React from "react";
import {
    Link as ChakraLink,
    Spinner,
    useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { DownloadIcon, AddIcon } from "@chakra-ui/icons";

const Link = ({ link, label, index, onClick, icon, loading, disabled }) => {
    const bg = useColorModeValue("gray.200", "gray.700");
    return (
        <ChakraLink
            px={2}
            disabled={disabled}
            py={1}
            rounded={"md"}
            hover={{
                textDecoration: "none",
                bg,
            }}
            as={RouterLink}
            to={link}
            key={index}
            onClick={onClick}
        >
            {label}{" "}
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {icon === "download" && <DownloadIcon mx="2px" />}
                    {icon === "add" && <AddIcon mx="2px" />}
                </>
            )}
        </ChakraLink>
    );
};

export default Link;
