import axios from "axios";
import { isLogin } from "../util/authHelper";
const { REACT_APP_API_URL } = process.env;

const options = {
    baseURL: REACT_APP_API_URL,
    validateStatus: (status) => {
        return [200, 201, 404, 401].includes(status);
    },
    headers: {
        "Content-Type": "application/json",
    },
};

let instance = axios.create(options);

if (isLogin()) {
    // Set the AUTH token for any request
    instance.interceptors.request.use((config) => {
        const token = isLogin();
        config.headers.Authorization = token ? `${token}` : "";
        return config;
    });
}

export default instance;
