import React from "react";
import { createGlobalStyle } from "styled-components";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { Link, Stack, Text } from "@chakra-ui/react";
import { dateParsed } from "../../util/dateHelper";
import { useState } from "react";

const DatePickerWrapperStyles = createGlobalStyle`
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: var(--chakra-colors-gray-200) !important;
  color: #000;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  color: var(--chakra-colors-gray-200) !important;
  background-color: var(--chakra-colors-gray-500) !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
  width: 1rem !important;
  height: 1rem !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  color: var(--chakra-colors-gray-200) !important;
  background-color: var(--chakra-colors-gray-500) !important;
}
.DayPicker-Month{
  margin: 1em;
}
`;

const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];
const WEEKDAYS_LONG = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercóles",
    "Jueves",
    "Viernes",
    "Sabado",
];
const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];

export default function FilterByCalendar({ from, to, handleDayClick }) {
    const modifiers = { start: from, end: to };
    const [hidden, setHidden] = useState(true);

    const handleHidden = () => {
        setHidden(!hidden);
    };
    return (
        <Stack>
            <Text fontSize="xl">Filtro por fecha</Text>
            <Stack direction={["column", "row"]}>
                <Link onClick={handleHidden}>Desde: {dateParsed(from)}</Link>
                {to && from.getTime() !== to.getTime() && (
                    <Link onClick={handleHidden}>Hasta: {dateParsed(to)}</Link>
                )}
            </Stack>
            <Stack display={`${hidden ? "none" : "flex"}`}>
                <DayPicker
                    className="Selectable"
                    months={MONTHS}
                    WEEKDAYS_LONG={WEEKDAYS_LONG}
                    WEEKDAYS_SHORT={WEEKDAYS_SHORT}
                    numberOfMonths={2}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={handleDayClick}
                />

                <DatePickerWrapperStyles />
            </Stack>
        </Stack>
    );
}
