import React, { useState, useEffect } from "react";
import { Stack, Text, Flex } from "@chakra-ui/layout";
import { useForm } from "react-hook-form";
import clientRequest from "../api/clientRequests";
import invoicesRequests from "../api/invoicesRequests";
import { formatCurrency } from "../util/currencyHelper";
import SelectClient from "../components/Clients/SelectClient";
import FiltersTable from "../components/Table/Filters";
import chargesRequests from "../api/chargesRequests";
import Table from "../components/Table";
import generateTableContent from "../util/generateTableContent";
import clientRequests from "../api/clientRequests";

const CCClient = () => {
    const form = useForm({
        defaultValues: {
            // clientSelected: false,
        },
    });

    const columns = [
        { displayName: "Fecha", key: "date", position: 0 },
        { displayName: "Comprobante", key: "payment_type", position: 1 },
        { displayName: "N° Comprobante", key: "ncomprobante", position: 2 },
        { displayName: "Debe", key: "debe", position: 3 },
        { displayName: "Haber", key: "haber", position: 4 },
        { displayName: "Saldo", key: "total", position: 5 },
    ];

    const { register, watch } = form;
    const [clientData, setSalesClient] = useState([]);
    const [render, setRender] = useState(false);
    const renderData = { render, setRender };
    const [clients, setClients] = useState([]);
    const clientSelected = watch("clientId");
    const [rows, setRows] = useState([]);
    const [rowsFiltered, setRowsFiltered] = useState([]);
    const clientId = watch("clientId");

    handleClients();
    handleClientSelected();

    const getVentas = async () => {
        if (clientData?.sales) {
            const salesById = await Promise.all(
                clientData?.sales?.map(async (saleId) => {
                    const purchaseRecord = await invoicesRequests.getRecord(
                        saleId,
                    );
                    const parsedPurchase = purchaseRecord[0];
                    return { ...parsedPurchase };
                }),
            );
            // const filterByPending = salesById?.filter(
            //     (sale) => sale.status === "pending",
            // );
            const parsedDebe = salesById?.map((sale) => {
                return {
                    ...sale,
                    debe: sale.total,
                    ncomprobante: sale.invoiceId,
                    payment_type: "factura",
                };
            });
            return parsedDebe;
        }
        return [];
    };

    const getCobros = async () => {
        if (clientData?.charges) {
            const chargesById = await Promise.all(
                clientData?.charges?.map(async (chargeId) => {
                    const purchaseRecord = await chargesRequests.getRecord(
                        chargeId,
                    );
                    const parsedPayment = purchaseRecord[0];
                    return { ...parsedPayment };
                }),
            );
            const parsedHaber = chargesById?.map((charge) => {
                return {
                    ...charge,
                    haber: charge.total,
                    payment_type: "recibo",
                    ncomprobante: charge.chargePdfId,
                };
            });
            return parsedHaber;
        }
        return [];
    };

    const generateVentasYCobros = async () => {
        let total = 0;
        if (clientData) {
            const ventas = await getVentas();

            const cobros = await getCobros();

            if (ventas && cobros) {
                const initialChecckingAccount =
                    generateInitialChecckingAccount();

                let ventasYCobros = [, ...ventas, ...cobros];
                ventasYCobros.unshift(initialChecckingAccount);

                // sorting with latest date
                const filterdPayments = ventasYCobros
                    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                    .map((paymentOrPruchase) => {
                        if (paymentOrPruchase.debe) {
                            total += paymentOrPruchase.debe;
                        }
                        if (paymentOrPruchase.haber) {
                            total -= Math.abs(paymentOrPruchase.haber);
                        }
                        return {
                            ...paymentOrPruchase,
                            total,
                        };
                    });
                return filterdPayments;
            }
        }
        return [];

        function generateInitialChecckingAccount() {
            const initialChecckingAccount = {
                date: "",
                debe: null,
                haber: null,
                ncomprobante: "Valor en Cuenta Corriente Inicial",
            };
            if (clientData?.initial_checking_account > 0) {
                initialChecckingAccount.debe =
                    clientData?.initial_checking_account;
            } else {
                initialChecckingAccount.haber =
                    clientData?.initial_checking_account;
            }
            return initialChecckingAccount;
        }
    };

    useEffect(async () => {
        setRowsFiltered([]);
        setRows([]);
        const response = await generateVentasYCobros();
        if (response.length === 0) {
            setRowsFiltered([]);
            return setRows("Any");
        }
        setRowsFiltered(response);
        return setRows(response);
    }, [clientData]);

    const chargesTableContent = generateTableContent(
        columns,
        rows,
        renderData,
        clientRequest,
        null,
    );

    const rowsFilteredForPrint = {
        clientId: clientData?.id,
        checkingAccount: rows,
    };

    const TableVentasYCobrosCliente = (
        <>
            <FiltersTable
                title={"Ventas y Cobros"}
                filteredData={rowsFiltered}
                originalData={rows}
                setData={setRows}
                request={clientRequests}
                customPrint
                filteredDataForPrint={rowsFilteredForPrint}
                customFilenamePdf={`Informe Saldo Cta Cte ${clientData?.name}`}
            />
            <Table tableContent={chargesTableContent} />
        </>
    );

    const Header = (
        <Flex
            direction={["column", "row"]}
            justifyContent="space-between"
            p={8}
            align="center"
        >
            <Stack>
                <Text fontSize="large">
                    Cuenta corriente cliente:{" "}
                    <SelectClient form={form} title=" " />
                </Text>
            </Stack>
            {clientId && (
                <>
                    <Stack>
                        <Text fontSize="xl">Saldo</Text>
                        <Text>
                            {formatCurrency(clientData?.checkingAccount)}
                        </Text>
                    </Stack>
                    <Stack>
                        <Text fontSize="xl">Datos Personales</Text>
                        <Flex direction="column" justifyContent="space-evenly">
                            <Text>CUIT: {clientData?.cuit}</Text>
                            <Text>Dirección: {clientData?.address}</Text>
                            <Text>Comentario: {clientData?.comment}</Text>
                        </Flex>
                    </Stack>
                </>
            )}
        </Flex>
    );
    return (
        <>
            <Stack
                border="1px solid var(--chakra-colors-gray-200)"
                display="column"
                direction={["column", "row"]}
                p={4}
            >
                {Header}
                {TableVentasYCobrosCliente}
            </Stack>
        </>
    );

    function handleClients() {
        useEffect(async () => {
            const clients = await clientRequest.getRecords();
            setClients(clients);
        }, []);
    }

    function handleClientSelected() {
        useEffect(() => {
            if (clients) {
                const clientFinded = clients?.find(
                    (provider) => provider.id === clientSelected,
                );

                setSalesClient(clientFinded);
            }
        }, [clientSelected, clients]);
    }
};

export default CCClient;
