import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Stack, HStack, Text, Select } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { DateUtils } from "react-day-picker";
import { useForm } from "react-hook-form";
import clientRequests from "../../../api/clientRequests";
import { addDaysToDate } from "../../../util/dateHelper";
import SelectClient from "../../Clients/SelectClient";
import Link from "../../Link";
import FilterByCalendar from "../../Sales/FilterByCalendar";

const FiltersTable = ({
    filteredData,
    setData,
    originalData,
    title,
    children,
    request,
    filterByClient = false,
    filterByStatus = false,
    customPrint = false,
    filteredDataForPrint,
    customFilenamePdf = false,
}) => {
    const form = useForm({
        defaultValues: {
            isVisible: false,
        },
    });
    const { register, watch, setValue } = form;
    const isVisible = watch("isVisible");
    const clientSelected = watch("clientId");
    const stateSelected = watch("stateSelected");

    const [to, setTo] = useState(null);
    const [from, setFrom] = useState(null);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    const handleNewRangeFilters = (day) => {
        const oldRange = {
            from,
            to,
        };
        const newRange = DateUtils.addDayToRange(day, oldRange);
        setFrom(newRange.from);
        setTo(newRange.to);
    };

    handleClientsSelected();
    handleStateSelected();
    handleChangesDates();

    const downloadPDF = async () => {
        try {
            let pdf;
            setIsLoadingPdf(true);

            if (customPrint) {
                pdf = await request.downloadPDF(filteredDataForPrint);
            } else {
                const idAllSales = filteredData?.map(
                    (sale) => sale.id || sale._id,
                );
                pdf = await request.downloadPDF(idAllSales);
            }
            const pdfBlob = new Blob([pdf], { type: "application/pdf" });
            const blobUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement("a");
            link.href = blobUrl;
            if (customFilenamePdf) {
                console.log(clientSelected);
                if (customFilenamePdf === "Liquidación" && clientSelected) {
                    const clientData = await clientRequests.getRecords();
                    const filteredData = clientData.find(
                        (client) => client.id === clientSelected,
                    );
                    link.setAttribute(
                        "download",
                        `${customFilenamePdf} ${filteredData.name}.pdf`,
                    );
                } else {
                    link.setAttribute("download", `${customFilenamePdf} .pdf`);
                }
            } else {
                link.setAttribute(
                    "download",
                    `Impresión-desde-${from?.getMonth()}-hasta-${to?.getMonth()}.pdf`,
                );
            }
            link.click();
            link.remove();
            return URL.revokeObjectURL(blobUrl);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoadingPdf(false);
        }
    };

    const handleResetFilters = () => {
        setData(filteredData);
    };

    return (
        <Stack border={"1px solid rgba(66,66,66,0.1)"} py={4} px={2}>
            <HStack justifyContent={"space-between"}>
                <Text mx={2}>{title}</Text>
                <Stack
                    direction={["column", "row"]}
                    alignItems={["flex-end", "center"]}
                >
                    <Stack direction={"row"}>
                        <Link
                            onClick={() => {
                                handleResetFilters();
                                setValue("isVisible", !isVisible);
                            }}
                            label={
                                <Text>
                                    {isVisible ? (
                                        <>
                                            Quitar filtro
                                            <ViewOffIcon mx={2} />
                                        </>
                                    ) : (
                                        <>
                                            Filtrar
                                            <ViewIcon mx={2} />
                                        </>
                                    )}
                                </Text>
                            }
                        />
                    </Stack>
                    <Stack>
                        <Link
                            label="Imprimir"
                            icon="download"
                            onClick={downloadPDF}
                            loading={isLoadingPdf}
                        />
                    </Stack>
                    <Stack>{children}</Stack>
                </Stack>
            </HStack>
            {isVisible && (
                <Stack>
                    <Stack py={3} justifyContent="space-around">
                        <Stack py={2}>
                            <FilterByCalendar
                                from={from}
                                to={to}
                                handleDayClick={handleNewRangeFilters}
                            />
                        </Stack>
                        {filterByClient && (
                            <Stack py={2}>
                                <SelectClient
                                    form={form}
                                    placeholder={"Filtrar por cliente"}
                                    title={"Filtro por cliente"}
                                />
                            </Stack>
                        )}
                        {filterByStatus && (
                            <Stack py={2}>
                                <Text fontSize="xl">Filtro por estado</Text>
                                <Select
                                    variant="flushed"
                                    placeholder={"Filtrar por estado"}
                                    {...register(`stateSelected`)}
                                >
                                    <option value="all">Todos</option>
                                    <option value="pending">Pendiente</option>
                                    <option value="processed">Procesada</option>
                                </Select>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );

    function handleClientsSelected() {
        useEffect(async () => {
            if (clientSelected === "all") {
                if (
                    stateSelected === "processed" ||
                    stateSelected === "pending"
                ) {
                    const filterByStateSelected = filteredData.filter(
                        (sale) => sale.status === stateSelected,
                    );
                    return setData(filterByStateSelected);
                }
                return setData(originalData);
            } else if (clientSelected) {
                const filterByClientSelected = filteredData?.filter(
                    (sale) => sale.client.id === clientSelected,
                );
                return setData(filterByClientSelected);
            } else if (stateSelected === "all" && clientSelected === "all") {
                return setData(originalData);
            }
        }, [clientSelected]);
    }

    function handleStateSelected() {
        useEffect(async () => {
            if (stateSelected === "processed" || stateSelected === "pending") {
                if (clientSelected && clientSelected !== "all") {
                    const filterByStateAndClientSelected = filteredData.filter(
                        (sale) =>
                            sale.status === stateSelected &&
                            sale.client.id === clientSelected,
                    );
                    return setData(filterByStateAndClientSelected);
                }
                const filterByStateSelected = filteredData.filter(
                    (sale) => sale.status === stateSelected,
                );
                return setData(filterByStateSelected);
            } else if (stateSelected === "all") {
                if (clientSelected && clientSelected !== "all") {
                    const filterByClientSelected = filteredData.filter(
                        (sale) => sale.client.id === clientSelected,
                    );
                    return setData(filterByClientSelected);
                }
                return setData(originalData);
            } else if (stateSelected === "all" && clientSelected === "all") {
                return setData(originalData);
            }
        }, [stateSelected]);
    }

    function handleChangesDates() {
        useEffect(() => {
            if (from || to) {
                const fromUnixTime = from.getTime() / 1000;
                const toUnixTime = addDaysToDate(to, 1).getTime() / 1000;
                const filteredRemitos = filteredData?.filter(
                    (sale) =>
                        new Date(sale.date).getTime() / 1000 <= toUnixTime &&
                        new Date(sale.date).getTime() / 1000 >= fromUnixTime,
                );
                if (filteredRemitos?.length === 0) {
                    return setData("vacio-por-fecha");
                }
                return setData(filteredRemitos);
            }
        }, [from, to]);
    }
};

export default FiltersTable;
