import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import NavDropdown from "./NavDropdown";
import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    Stack,
    Container,
} from "@chakra-ui/react";
import Routes from "../config/Routes";
import { useLocation } from "react-router-dom";
import { isAdmin, isLogin, logout } from "../util/authHelper";
import Link from "./Link";
import adminDropdown from "../config/Navbar/adminDropdown";
import operatorDropdown from "../config/Navbar/operatorDropwdown";

export default function Navbar() {
    const location = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleLogout = () => logout();

    const logoutButton = isLogin() && (
        <HStack as={"nav"} justify={"flex-end"} direction={"column"}>
            <Link label="Salir" link="/" onClick={handleLogout} />
        </HStack>
    );

    if (location.pathname === "/") {
        return (
            <Box>
                <Routes />
            </Box>
        );
    }

    const navBar = (
        <>
            <Flex
                h={16}
                px={[2, 16]}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <IconButton
                    size={"md"}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={"Open Menu"}
                    display={{ md: !isOpen ? "none" : "inherit" }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems={"center"}>
                    <HStack
                        as={"nav"}
                        spacing={4}
                        display={{ base: "none", md: "flex" }}
                    >
                        <Link label="Inicio" link="/home" />
                        {isAdmin() ? (
                            <NavDropdown navDropdown={adminDropdown} />
                        ) : (
                            <NavDropdown navDropdown={operatorDropdown} />
                        )}
                    </HStack>
                </HStack>

                {logoutButton}
            </Flex>
            {isOpen && (
                <Stack as={"nav"}>
                    <Link label="Inicio" link="/home" />
                    {isAdmin() ? (
                        <NavDropdown navDropdown={adminDropdown} />
                    ) : (
                        <NavDropdown navDropdown={operatorDropdown} />
                    )}
                </Stack>
            )}
        </>
    );

    return (
        <>
            {navBar}
            <Container maxW="container.xxl" p={[2, 4, 6]}>
                <Routes />
            </Container>
        </>
    );
}
